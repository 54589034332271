/* eslint-disable @next/next/no-img-element */
import { Tooltip } from '@chakra-ui/react';
import googleMapReact, { Bounds, Coords, fitBounds } from 'google-map-react';
import GoogleMapReact from 'google-map-react';
import Supercluster, { PointFeature } from "supercluster";



export function getMapBounds<T>(points: Supercluster.PointFeature<MapMarkerProperties<T>>[]) {
  const latLngArray = points.map((marker) => {
    return { lat: marker.geometry.coordinates[0], lng: marker.geometry.coordinates[1] }
  })

  return getBounds(latLngArray, 300, 500)
}

export function getPoints<T>(mapData: MapData<T>): PointFeature<MapMarkerProperties<T>>[] {
  return mapData.markers.map((marker, markerIndex) => {

    return {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [marker.lng, marker.lat]
      },
      properties: {
        cluster: false,
        key: markerIndex,
        point_count: 0,
        groupList: marker.groupList
      }

    }
  })
}


export const MarkerMap = ({ defaultProps, markers }) => {
  return (
    <GoogleMapReact
      bootstrapURLKeys={{ key: process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY }}
      defaultCenter={defaultProps.center ?? { lat: 0, lng: 0 }}
      defaultZoom={defaultProps.zoom ?? 0}
      center={defaultProps.center ?? { lat: 0, lng: 0 }}
      zoom={defaultProps.zoom ?? 0}
      options={{
        styles: googleMapStyle,
        minZoom: 0,
        ...defaultProps
      }}
    >
      {
        markers?.map((marker, index) => {
          return (
            <CommunityMapMarker
              key={index}
              lat={marker.lat}
              lng={marker.lng}
              name={marker.name}
            />
          )
        })
      }
    </GoogleMapReact>
  )
}

const CommunityMapMarker = (props: { src?: string, lat: number, lng: number, name: string }): JSX.Element => {
  return (
    <Tooltip label={props.name}>
      <img
        style={{
          position: 'absolute',
          transform: 'translate(-50%, -50%)'
        }}
        src={props.src ?? '/images/map/community-marker.png'}
        width={'10px'}
        height={'10px'}
        alt={props.name}

      />
    </Tooltip>
  )
}

export interface GetBoundsReturn {
  center: Coords;
  zoom: number;
  newBounds?: Bounds;
}

export const getBounds = (points: Coords[], width: number, height: number): GetBoundsReturn => {
  let maxLat, maxLng, minLat, minLng;

  if (points && points.length > 0) {
    for (let point of points) {
      if (point.lat) {
        if (!maxLat) {
          maxLat = point.lat;
          maxLng = point.lng;
          minLat = point.lat;
          minLng = point.lng;
        } else {
          if (maxLat > point.lat) maxLat = point.lat;
          if (maxLng < point.lng) maxLng = point.lng;
          if (minLat < point.lat) minLat = point.lat;
          if (minLng > point.lng) minLng = point.lng;
        }
      }
    }

    const ne = { lat: maxLat, lng: maxLng };
    const sw = { lat: minLat, lng: minLng };

    const size = {
      width: width, // Map width in pixels
      height: height, // Map height in pixels
    };

    //if we only have one point, use that as the center
    if (points.length === 1) {
      return { center: ne, zoom: 4 };
    }
    else {
      return fitBounds({ ne, sw }, size);
    }

  } else {
    return { center: { lat: 0, lng: 0 }, zoom: 4 };
  }
}

export const createMapData = <T extends MapMarkerData>(data: T[]): MapData<T> => {
  //FUTURE: Support grouping by a field to support multiple data items in a single pin.

  //We filter out items where lat/lng are both 0.  That won't be a valid location for us and means we don't have accurate map data

  let returnData: MapData<T> = {
    centerLat: 0,
    centerLng: 0,
    maxLat: 0,
    maxLng: 0,
    minLat: 0,
    minLng: 0,
    markers: []
  }

  returnData.minLat = data.filter(d => d.lat !== 0 && d.lng !== 0).map(d => d.lat).reduce((prev, curr) => prev < curr ? prev : curr, 90)
  returnData.maxLat = data.filter(d => d.lat !== 0 && d.lng !== 0).map(d => d.lat).reduce((prev, curr) => prev > curr ? prev : curr, -90)
  returnData.minLng = data.filter(d => d.lat !== 0 && d.lng !== 0).map(d => d.lng).reduce((prev, curr) => prev < curr ? prev : curr, 180)
  returnData.maxLng = data.filter(d => d.lat !== 0 && d.lng !== 0).map(d => d.lng).reduce((prev, curr) => prev > curr ? prev : curr, -180)
  returnData.centerLat = (returnData.minLat + returnData.maxLat) / 2
  returnData.centerLng = (returnData.minLng + returnData.maxLng) / 2

  data.forEach(d => {
    if (d.lat !== 0 && d.lng !== 0) {
      returnData.markers = [
        ...returnData.markers,
        {
          id: d.id.toString(),
          lat: d.lat,
          lng: d.lng,
          name: d.name,
          groupList: [d]
        }
      ]
    }
  })

  return returnData
}

export const googleMapStyle: googleMapReact.MapTypeStyle[] = [
  {
    "featureType": "administrative",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#444444"
      }
    ]
  },
  {
    "featureType": "administrative.country",
    "elementType": "all",
    "stylers": []
  },
  {
    "featureType": "administrative.province",
    "elementType": "all",
    "stylers": []
  },
  {
    "featureType": "administrative.locality",
    "elementType": "all",
    "stylers": [
      {
        "visibility": "simplified"
      }
    ]
  },
  {
    "featureType": "administrative.neighborhood",
    "elementType": "all",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "featureType": "administrative.land_parcel",
    "elementType": "all",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "featureType": "landscape",
    "elementType": "all",
    "stylers": [
      {
        "color": "#f2f2f2"
      }
    ]
  },
  {
    "featureType": "landscape.man_made",
    "elementType": "all",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "featureType": "landscape.natural",
    "elementType": "all",
    "stylers": [
      {
        "visibility": "simplified"
      }
    ]
  },
  {
    "featureType": "landscape.natural.landcover",
    "elementType": "all",
    "stylers": [
      {
        "visibility": "simplified"
      }
    ]
  },
  {
    "featureType": "landscape.natural.terrain",
    "elementType": "all",
    "stylers": [
      {
        "visibility": "simplified"
      }
    ]
  },
  {
    "featureType": "poi",
    "elementType": "all",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "featureType": "road",
    "elementType": "all",
    "stylers": [
      {
        "saturation": -100
      },
      {
        "lightness": 45
      }
    ]
  },
  {
    "featureType": "road.highway",
    "elementType": "all",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "featureType": "road.highway.controlled_access",
    "elementType": "all",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "featureType": "road.arterial",
    "elementType": "all",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "featureType": "road.arterial",
    "elementType": "labels.icon",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "featureType": "road.local",
    "elementType": "all",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "featureType": "transit",
    "elementType": "all",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "featureType": "water",
    "elementType": "all",
    "stylers": [
      {
        "color": "#bac6cb"
      },
      {
        "visibility": "on"
      }
    ]
  }
]