import GoogleMapReact from "google-map-react"
import { ComponentType, useEffect, useMemo, useRef, useState } from "react";
import { getMapBounds, getPoints, googleMapStyle } from "../../utils/maps";
import useSupercluster from "../../utils/useSupercluster";
import MapMarker from "./MapMarker";


export interface Props<T> {
  mapData: MapData<T>,
  SingleInfoWindow?: ComponentType<SingleMapInfoProps<T>>
  MultiInfoWindow?: ComponentType<MultiMapInfoProps<T>>
  allowMarkerClick: boolean

}


const PpmMap = <T,>(props: Props<T>): JSX.Element => {
  const mapRef = useRef<any>();



  const points = useMemo(() => getPoints<T>(props.mapData), [props.mapData])
  const mapCenterAndBounds = useMemo(() => getMapBounds<T>(points), [points])

  const [zoom, setZoom] = useState<number>(mapCenterAndBounds.zoom);
  const [bounds, setBounds] = useState<[number, number, number, number]>([
    props.mapData.maxLng,
    props.mapData.minLat,
    props.mapData.maxLng,
    props.mapData.minLng,
  ]);

  const [activeInfoWindow, setActiveInfoWindow] = useState<number | null>(null);

  const { clusters, supercluster } = useSupercluster({
    points,
    bounds,
    zoom,
    options: { radius: 50, maxZoom: 10 }
  });

  useEffect(() => {
    setTimeout(() => {
      const node = mapRef.current;

      node?.setZoom(zoom);
      node?.panTo({ lat: props.mapData.centerLat, lng: props.mapData.centerLng });
    }, 500)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  return (
    <GoogleMapReact
      onClick={() => {props.allowMarkerClick ? setActiveInfoWindow(null) : {} }}
      bootstrapURLKeys={{ key: process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY }}
      defaultCenter={{ lat: props.mapData.centerLat, lng: props.mapData.centerLng }}
      defaultZoom={mapCenterAndBounds.zoom}
      yesIWantToUseGoogleMapApiInternals
      onChange={({ zoom, bounds }) => {
        setZoom(zoom);
        setBounds([
          bounds.nw.lng,
          bounds.se.lat,
          bounds.se.lng,
          bounds.nw.lat
        ]);
      }}
      onGoogleApiLoaded={({ map }) => {
        mapRef.current = map;
      }}
      options={{
        styles: googleMapStyle,
        minZoom: 0,
        maxZoom: 12,
        // journal marker onClick events don't fire in fullscreen mode
        fullscreenControl: false
      }}
    >
      {clusters.map((mapPin, index) => {
        const [longitude, latitude] = mapPin.geometry.coordinates;

        const {
          cluster: isCluster,
          point_count: pointCount
        } = mapPin.properties;

        if (isCluster) {
          return (
            mapRef &&
            <ClusterMarker
              key={`cluster-${mapPin.id}`}
              lat={latitude}
              lng={longitude}
            >
              <div
                className="cluster-marker"
                style={{
                  width: `${20}px`,
                  height: `${20}px`,
                  cursor: 'pointer'
                }}
                onClick={() => {
                  const expansionZoom = Math.min(
                    supercluster.getClusterExpansionZoom(Number(mapPin.id)),
                    20
                  );
                  mapRef?.current?.setZoom(expansionZoom);
                  mapRef?.current?.panTo({ lat: latitude, lng: longitude });
                }}
              >
                {pointCount}
              </div>
            </ClusterMarker>

          );
        }

        return (
          <MapMarker
            key={`marker-${mapPin.properties.key}`}
            lat={latitude}
            lng={longitude}
            infoIndex={mapPin.properties.key}
            groupList={mapPin.properties.groupList}
            setActiveInfoWindow={setActiveInfoWindow}
            activeInfoWindow={activeInfoWindow}
          >
            {
              props.allowMarkerClick && mapPin.properties.groupList?.length === 1 &&
              <props.SingleInfoWindow data={mapPin.properties.groupList[0]} />
               
            }
            {
              props.allowMarkerClick && mapPin.properties.groupList?.length > 1 &&
              <props.MultiInfoWindow data={mapPin.properties.groupList} />
            }
          </MapMarker>
        );
      })}


    </GoogleMapReact>
  )
}

const ClusterMarker = (props: { children, lat: number, lng: number }): JSX.Element => {
  return props.children;
}

export default PpmMap