import { Box } from "@chakra-ui/react";

interface HeroFullBackgroundAutoHeightProps {
  children: JSX.Element;
  photoSrc: string;
  shading?: number;
  minH?: string;
}

const HeroFullBackgroundAutoHeight = ({ children, photoSrc, shading = 600, minH = 'calc(100vh - 132px)' }: HeroFullBackgroundAutoHeightProps): JSX.Element => {
  return (
    <Box
      w={'full'}
      bgImage={`url('${photoSrc}')`}
      sx={{
        backgroundPositionX: '50%',
        backgroundPositionY: '50%',
      }}
      bgSize="cover"
      bgPosition="center"
      bgRepeat="no-repeat"
    >
      <Box
        bgGradient={`linear(to-r, blackAlpha.${shading}, blackAlpha.${shading})`}
        minH={minH}
      >
        {children}
      </Box>
    </Box>
  )
}

export default HeroFullBackgroundAutoHeight;